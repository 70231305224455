var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['d-flex', 'justify-center', _vm.vertical && 'flex-column-reverse'],attrs:{"id":"card-nav"}},[(_vm.prevBtn)?_c('v-btn',{class:[
      'primary--text',
      _vm.vertical ? 'full-width mb-5' :'mr-2',
      'flex-grow-1',
      !_vm.onboarding && 'nav-btn'
    ],attrs:{"color":"primary","id":"prev-btn","outlined":"","loading":_vm.prevLoading,"disabled":_vm.prevDisabled},on:{"click":_vm.previous}},[_vm._v("\n    "+_vm._s(_vm.prevText)+"\n  ")]):_vm._e(),_vm._v(" "),_c('v-btn',{class:[
      'black--text',
      (_vm.prevBtn && !_vm.vertical) && 'ml-2',
      'flex-grow-1',
      _vm.onboarding ? 'next-btn' : 'nav-btn',
      _vm.vertical && 'mb-5 full-width' ],attrs:{"color":"primary","id":"next-btn","loading":_vm.nextLoading,"disabled":_vm.nextDisabled},on:{"click":_vm.next}},[_vm._v("\n    "+_vm._s(_vm.nextText)+"\n  ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }