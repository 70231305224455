<template>
  <div id="card-nav" :class="['d-flex', 'justify-center', vertical && 'flex-column-reverse']">
    <v-btn
      v-if="prevBtn"
      color="primary"
      id="prev-btn"
      :class="[
        'primary--text',
        vertical ? 'full-width mb-5' :'mr-2',
        'flex-grow-1',
        !onboarding && 'nav-btn'
      ]"
      @click="previous"
      outlined
      :loading="prevLoading"
      :disabled="prevDisabled"
    >
      {{ prevText }}
    </v-btn>
    <v-btn
      color="primary"
      id="next-btn"
      :class="[
        'black--text',
        (prevBtn && !vertical) && 'ml-2',
        'flex-grow-1',
        onboarding ? 'next-btn' : 'nav-btn',
        vertical && 'mb-5 full-width',
      ]"
      @click="next"
      :loading="nextLoading"
      :disabled="nextDisabled"
    >
      {{ nextText }}
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'CardNav',
  props: {
    nextText: {
      type: String,
      required: true,
    },
    prevText: {
      type: String,
      required: true,
    },
    nextLoading: {
      type: Boolean,
      default: false,
    },
    nextDisabled: {
      type: Boolean,
      default: false,
    },
    prevLoading: {
      type: Boolean,
      default: false,
    },
    prevDisabled: {
      type: Boolean,
      default: false,
    },
    onboarding: {
      type: Boolean,
      default: false,
    },
    prevBtn: {
      type: Boolean,
      default: true,
    },
    vertical: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    previous() {
      this.$emit('previous');
    },
    next() {
      this.$emit('next');
    },
  },
};
</script>

<style scoped>
.nav-btn {
  width: 48%;
}
.next-btn {
  width: 65%;
}
.full-width {
  width: 100% !important;
}
</style>
