<template>
  <div>
    <v-card
      color="element"
      class="mx-auto"
      max-width="640"
    >
      <v-card-title class="mobile-title">{{ $t('subscription.amount.title') }}</v-card-title>

      <div class="px-4 small-mobile-text">
        <div
          v-if="investorSubscriptionInformation.subscribedTokens > 0"
          class="d-flex justify-space-between secondaryText--text mb-1"
        >
          <div>{{ $t('subscription.amount.invest') }}</div>
          <div>{{ $n(investorSubscriptionInformation.subscribedTokens, 'integer') }} Token</div>
        </div>
        <div class="d-flex justify-space-between secondaryText--text mb-1">
          <div>{{ $t('subscription.amount.min') }}</div>
          <div>{{ $n(investorSubscriptionInformation.minSubscriptionAmount, 'integer') }} €</div>
        </div>
        <div class="d-flex justify-space-between secondaryText--text mb-1">
          <div>{{ $t('subscription.amount.max') }}</div>
          <div>{{ $n(investorSubscriptionInformation.spendableCashBalance, 'float') }} €</div>
        </div>
        <div class="d-flex justify-space-between secondaryText--text mb-1">
          <div>{{ $t('subscription.amount.token') }}</div>
          <div>
            {{ $n(investorSubscriptionInformation.totalOfferedTokens, 'integer') }} Tokens /
             {{ $n(investorSubscriptionInformation.totalTokenSupply, 'integer') }} Tokens
          </div>
        </div>
        <div class="d-flex justify-space-between secondaryText--text mb-1">
          <div class="d-flex">
            <div class="defaultText--text mr-2">{{ $t('subscription.amount.subscriptionAmount') }}</div>
            <div>1 Token = {{ $n(investorSubscriptionInformation.tokenPrice, 'integer') }} Euro</div>
          </div>
          <div class="defaultText--text amount-overflow">{{ $n(subscriptionAmount, 'integer') }} €</div>
        </div>
        <div class="d-flex justify-space-between secondaryText--text">
          <div class="d-flex">
            <div class="mr-2">{{ $t('subscription.amount.debitAmount') }}</div>
            <div>
              <v-tooltip top color="backgroundLight" :max-width="300">
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    v-bind="attrs"
                    v-on="on"
                    color="backgroundLight"
                    class="info-chip"
                    small
                  >i</v-chip>
                </template>
                <span>{{ $t('subscription.amount.tooltipMessage') }}</span>
              </v-tooltip>
            </div>
          </div>
          <div class="amount-overflow">{{ $n(debitAmount, 'float')}} €</div>
        </div>

        <div class="mt-3">
          <v-form
            v-model="isFormValid"
            @submit.prevent="next"
          >
            <v-text-field
              id="amount"
              class="amountErrorMessage"
              :rules="rulesList"
              :label="`${$t('subscription.amount.amountToken')} *`"
              required
              outlined
              v-model="displayAmount"
              :validate-on-blur="validateOnBlur"
            ></v-text-field>
          </v-form>
        </div>

        <div
          v-if="
            privateStandardInvestor &&
            amount >= 1000 &&
            amount <= investorSubscriptionInformation.maxSubscribableTokens
          "
        >
          <p class="font-weight-bold primary--text mobile-text">
            {{ $t('subscription.amount.checkbox.title') }}
          </p>
          <p
            v-if="totalAmount <= 10000"
            class="primary--text justify-text mobile-text"
          >
            {{ $t('subscription.amount.checkbox.description1') }}
          </p>
          <p
            v-else
            class="primary--text justify-text"
          >
            {{ $t('subscription.amount.checkbox.description2') }}
          </p>

          <v-checkbox
            v-model="firstCheckbox"
            color="primary"
          >
            <template slot="label">
              <div class="justify-text mobile-text mobile-text">
                {{ $t('subscription.amount.checkbox.text1') }}
              </div>
            </template>
          </v-checkbox>
          <v-checkbox
            v-if="totalAmount <= 10000"
            v-model="secondCheckbox"
            class="mt-0"
            color="primary"
          >
            <template slot="label">
              <div class="justify-text mobile-text">
                {{ $t('subscription.amount.checkbox.text2') }}
              </div>
            </template>
          </v-checkbox>
        </div>

        <v-btn
          v-if="amount > investorSubscriptionInformation.maxSubscribableTokens"
          color="warning"
          class="mb-5"
          block
          outlined
          @click="fixError"
        >
          {{ $t('subscription.amount.maxErrorBtn', {
            maxAmount: this.$n(this.investorSubscriptionInformation.maxSubscribableTokens)
          }) }}
        </v-btn>

        <div
          class="pb-5"
        >
          <CardNav
            :nextText="$t('subscription.navigation.next')"
            :prevText="$t('subscription.navigation.back')"
            @next="next"
            @previous="previousStep(1)"
            :prevDisabled="loading"
            :nextDisabled="
              !isFormValid
              || (!firstCheckbox && !secondCheckbox && privateStandardInvestor)
              || (amount >= 10000 && !firstCheckbox && privateStandardInvestor)
            "
            :nextLoading="loading"
          />
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import i18n from '@/plugins/i18n';
import CardNav from '@/components/Shared/CardNav.vue';

export default {
  name: 'Amount',
  components: {
    CardNav,
  },
  data() {
    return {
      rulesList: [
        (v) => !!v || i18n.t('validators.required'),
      ],
      currencyTypes: ['Token', 'Euro'],
      currency: 'Token',
      displayAmount: null,
      amount: null,
      isFormValid: false,
      loading: false,
      firstCheckbox: false,
      secondCheckbox: false,
      validateOnBlur: true,
    };
  },
  computed: {
    ...mapGetters('subscription', [
      'investorSubscriptionInformation',
      'orderId',
      'subscriptionAmount',
      'debitAmount',
    ]),
    privateStandardInvestor() {
      return this.investorSubscriptionInformation.investorClassification === 'Private'
        && this.investorSubscriptionInformation.emissionType === 'Standard';
    },
    totalAmount() {
      const { subscribedTokens, tokenPrice } = this.investorSubscriptionInformation;
      return subscribedTokens * tokenPrice + parseFloat(this.amount);
    },
  },
  watch: {
    displayAmount(newVal) {
      if (newVal >= this.investorSubscriptionInformation.minSubscriptionAmount) {
        this.validateOnBlur = false;
      }
      const amount = Number(newVal.replace(/[^0-9-]+/g, ''));
      this.displayAmount = this.$n(amount);
      this.amount = amount;
      this.setAmount(amount);
    },
  },
  created() {
    this.setAmount(0);
    const {
      minSubscriptionAmount,
      maxSubscribableTokens,
      maxSubscriptionType,
    } = this.investorSubscriptionInformation;
    this.rulesList.push(
      (v) => Number(v?.replace(/[^0-9-]+/g, '')) >= minSubscriptionAmount || i18n.t('validators.investAmount.min', {
        minAmount: this.$n(minSubscriptionAmount),
      }),
      (v) => Number(v?.replace(/[^0-9-]+/g, '')) <= maxSubscribableTokens
        || i18n.t(
          `validators.investAmount.max.${maxSubscriptionType}`,
          {
            maxAmount: this.$n(maxSubscribableTokens),
          },
        ),
    );
  },
  methods: {
    ...mapActions('subscription', [
      'previousStep',
      'nextStep',
      'checkAdequacy',
      'setAmount',
      'requestSubscriptionTokenAmount',
    ]),
    async next() {
      this.loading = true;
      const selfDisclosure = [];
      if (this.firstCheckbox) {
        selfDisclosure.push('Income');
      }
      if (this.secondCheckbox) {
        selfDisclosure.push('Wealth');
      }
      try {
        await this.requestSubscriptionTokenAmount({
          orderId: this.orderId,
          requestedNumberOfTokens: this.amount,
          selfDisclosure: this.investorSubscriptionInformation.investorClassification !== 'Private'
            ? null
            : selfDisclosure,
        });
      } catch (error) {
        this.$notify.error(error.message);
        this.loading = false;
        return;
      }
      if (this.investorSubscriptionInformation.investorClassification !== 'Private') {
        this.nextStep(2);
        this.loading = false;
        return;
      }
      try {
        const adequacy = await this.checkAdequacy();
        if (adequacy) {
          this.nextStep(2);
          this.loading = false;
          return;
        }
      } catch (error) {
        this.$notify.error(error.message);
        this.loading = false;
      }
      this.nextStep(1);
      this.loading = false;
    },
    fixError() {
      this.displayAmount = this.$n(this.investorSubscriptionInformation.maxSubscribableTokens);
    },
  },
};
</script>

<style lang="scss">
.amountErrorMessage {
  .v-text-field__details {
    margin-top: 12px !important;
    margin-bottom: 16px !important;
  }
  .v-messages__message {
    font-size: 16px;
    line-height: 24px;
    color: #E39828 !important;
  }
}

.justify-text {
  text-align: justify;
}

.info-chip {
  padding-left: 7px !important;
  padding-right: 7px !important;
  height: 16px !important;
}

.amount-overflow {
  max-width: 200px;
}

@media (max-width: 600px) {
  .mobile-title {
    font-size: 16px !important;
  }
  .mobile-text {
    font-size: 12px !important;
  }
  .small-mobile-text {
    font-size: 10px !important;
  }
  .amountErrorMessage {
    .v-messages__message {
      font-size: 12px;
    }
  }
  .amount-overflow {
    max-width: 60px;
  }
}
</style>
